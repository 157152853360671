
















































import {Component, Vue} from "vue-property-decorator";
import PayrollFileDTO from "@/dto/payroll/PayrollFileDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PayrollService from "@/services/payroll/PayrollService";
import PayrollFileCategories from "@/constants/PayrollFileCategories";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import {namespace} from "vuex-class";
import FileUpload from "@/components/common/FileUpload.vue";
import SavePayrollFileRequest from "@/dto/payroll/SavePayrollFileRequest";
import UploadFormWithCompanySelectModal from "@/components/payroll/UploadFormWithCompanySelectModal.vue";
import PayrollDocumentActionModal from "@/components/util/DocumentActionModal.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	components: {FileUpload}
})
export default class IndividualW2Forms extends Vue {

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Auth.Getter("hasAdministrativePrivileges")
	private hasAdministrativePrivileges!: boolean;

	private message = "";

	private successful = true;

	private forms: Array<PayrollFileDTO> = [];

	private employee = new PersonDTO(); // mock to prevent continuous null checking

	mounted(){
		this.loadPerson().then(
			() => this.loadForms()
		);
	}

	loadPerson(){
		this.startLoading();
		return PersonService.getById(Number.parseInt(this.$route.params.personId, 10)).then(
			response => {
				this.successful = true;
				this.stopLoading();
				this.employee = response.data;
			},
			error => {
				this.stopLoading();
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
			}
		);
	}

	loadForms(){
		this.startLoading();
		return PayrollService.getPayrollFilesByCategoryAndEmployeeId(PayrollFileCategories.F_W2, this.employee.id as number).then(
			(response) => {
				this.successful = true;
				this.forms = response.data;
			},
			(error) => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
			}
		).then(() => this.stopLoading());
	}

	onFilesUploaded(files: FileMetaDTO[], employerId: number, period: string){
		files.forEach(f => {
			PayrollService.savePayrollFile(new SavePayrollFileRequest(this.employee.id as number, employerId, period, f, PayrollFileCategories.F_W2)).then(
				response => {
					this.forms.push(response.data);
				}
			);
		});
	}

	showUploadModal() {
		this.$modal.show(
			UploadFormWithCompanySelectModal,
			{
				onFilesUploaded: this.onFilesUploaded,
				employee: this.employee
			}
		);
	}

	onDocumentClick(document: PayrollFileDTO) {
		if (this.hasAdministrativePrivileges) {
			this.showActions(document);
		} else {
			Vue.prototype.$docUtils.download(document.file.id);
		}
	}

	showActions(document: PayrollFileDTO) {
		this.$modal.show(
			PayrollDocumentActionModal,
			{
				onDelete: () => this.deleteDocument(document),
				onDownload: () => Vue.prototype.$docUtils.download(document.file.id)
			},
			{width: "50%", height: "auto"}
		);
	}

	deleteDocument(document: PayrollFileDTO) {
		this.message = "";
		this.startLoading();
		PayrollService.deleteDocument(document.id, 'PAYROLL_FILE').then(
			response => {
				this.message = "Deleted";
				this.successful = true;
				this.stopLoading();
				this.loadForms();
			},
			error => {
				this.successful = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.stopLoading();
			}
		);
	}
}
